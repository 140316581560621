import React from 'react'
import { Link, graphql } from 'gatsby'
import moment from 'moment'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
import ClientSwiper from '../components/ClientSwiper'
// import SampleForm from '../components/Form'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import slide1 from '../images/wsc-top100.jpg'
import slide2 from '../images/slider-5d.jpg'
import slide3 from '../images/slider1.jpg'
import slide4 from '../images/slider2.jpg'
import slide5 from '../images/slider3.jpg'
import slide6 from '../images/affiliates-slide_mini.jpg'

import WSCLight from '../images/wsc_logo_new.png'

import aboutImg from '../images/wsc-awards-combined.png'
import serv1 from '../images/serv1.png'
import serv2 from '../images/serv2.png'
import serv3 from '../images/serv3.png'
import serv4 from '../images/serv4.png'
import serv5 from '../images/serv5.png'
import serv6 from '../images/serv6.png'
import serv7 from '../images/serv7.png'
import aboutLogo from '../images/wsc-logo-colored.png'
import director1 from '../images/directors1.jpg'
import director2 from '../images/directors2.jpg'
import director3 from '../images/directors3.jpg'
import director4 from '../images/directors4.jpg'
import director5 from '../images/directors5.jpg'
import postImg from '../images/posted-img.png'

class IndexPage extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description
		console.log(this.props.data)
		return (
			<Layout location={this.props.location} title="">
				<SEO title={siteTitle} description={siteDescription} />
				<MySwiper slidesperview={1}>
					<div>
						<LazyLoadImage
							effect="blur"
							className="img-fluid lazy-load-image-background blur lazy-load-image-loaded"
							src={slide1}
							alt="WSC Top 100"
						/>
					</div>
					<div>
						<Link to="resources/property-tax-tools-app">
							<LazyLoadImage
								effect="blur"
								className="img-fluid lazy-load-image-background blur lazy-load-image-loaded"
								src={slide2}
								alt="Slider 2"
							/>
						</Link>
					</div>
					<div>
						<div className="slide3-text">
							<LazyLoadImage
								src={WSCLight}
								className="img-fluid mb-1"
								alt="WSC Light"
							/>
							<h3 className="mb-3">
								<em>Certified Practising Accountants and Business Advisors.</em>
							</h3>
							<h6 className="mb-4">
								WSC Group is your trusted advisor with a special focus in
								property investment tax, business advice and self-managed
								superannuation funds.
							</h6>
							<Link
								to="/contact"
								className="btnslide hvr-ripple-out"
								tabindex="0"
							>
								CONTACT NOW!
							</Link>
						</div>
						<LazyLoadImage
							effect="blur"
							className="img-fluid lazy-load-image-background blur lazy-load-image-loaded"
							src={slide3}
							alt="Slider 3"
						/>
					</div>
					<div>
						<div className="slide4-text">
							<div className="row">
								<div className="col">
									<h1>OUR SERVICES</h1>
									<h6>
										<em>
											WSC Group Certified Practicing Accountants provide
											business owners and professionals with a real alternative
											to traditional accountancy.
										</em>
									</h6>
									<ul className="list-unstyled ml-2">
										<li>
											<i className="fa fa-check" /> BUSINESS ADVISORY
										</li>
										<li>
											<i className="fa fa-check" /> PROPERTY INVESTMENT
										</li>
										<li>
											<i className="fa fa-check" /> SUPERANNUATION
										</li>
										<li>
											<i className="fa fa-check" /> FINANCIAL PLANNING
										</li>
										<li>
											<i className="fa fa-check" /> LOANS
										</li>
									</ul>
								</div>
							</div>
						</div>
						<LazyLoadImage
							effect="blur"
							className="img-fluid lazy-load-image-background blur lazy-load-image-loaded"
							src={slide4}
							alt="Slider 4"
						/>
					</div>
					<div>
						<div className="slide4-text">
							<div className="row">
								<div className="col">
									<h1>BUSINESS ADVISORY</h1>
									<h6>
										<em>
											WSC Group's Business Advisory Services extends well beyond
											traditional accounting activities.
										</em>
									</h6>
									<ul className="list-unstyled ml-2 business-list">
										<li>
											<i className="fa fa-arrow-circle-right" /> TAX CONSULTING
										</li>
										<li>
											<i className="fa fa-arrow-circle-right" /> ACCOUNTING
										</li>
										<li>
											<i className="fa fa-arrow-circle-right" /> MANAGEMENT
											CONSULTING-STRATEGY & PLANNING
										</li>
										<li>
											<i className="fa fa-arrow-circle-right" /> CORPORATE
											SECRETARIAL SERVICES
										</li>
										<li>
											<i className="fa fa-arrow-circle-right" /> CLOUD
											ACCOUNTING
										</li>
									</ul>
								</div>
							</div>
						</div>
						<LazyLoadImage
							effect="blur"
							className="img-fluid lazy-load-image-background blur lazy-load-image-loaded"
							src={slide5}
							alt="Slider 5"
						/>
					</div>
					<div>
						<div className="slide4-text">
							<div className="row">
								<div className="col">
									<h1>SUPERANNUATION</h1>
									<h6>
										<em>
											WSC Group has developed an expertise in assisting our
											clients.
										</em>
									</h6>
									<ul className="list-unstyled ml-2">
										<li>
											<i className="fa fa-arrow-circle-right" /> Establishment
											of a Self Managed Superannuation Fund
										</li>
										<li>
											<i className="fa fa-arrow-circle-right" /> Advice
											concerning Compliance Matters
										</li>
										<li>
											<i className="fa fa-arrow-circle-right" /> Advice
											concerning Taxation Benefits
										</li>
										<li>
											<i className="fa fa-arrow-circle-right" /> Controlling
											costs of Administration
										</li>
									</ul>
								</div>
							</div>
						</div>
						<LazyLoadImage
							effect="blur"
							className="img-fluid lazy-load-image-background blur lazy-load-image-loaded"
							src={slide6}
							alt="Slider 6"
						/>
					</div>
				</MySwiper>
				<div className="section" id="aboutsec">
					<div className="container text-center">
						<div className="row">
							<div className="col-md-5 col-sm-5 col-xs-5 leftabout">
								<img src={aboutImg} alt="About Img" />
							</div>
							<div className="col-md-7 col-sm-7 col-xs-7 rightabout text-left">
								<p className="detwscabout">
									WSC Group provide business owners and professionals with a
									real alternative to traditional financial services.
								</p>
								<p className="detwscabout">
									We will help you manage every aspect of your financial
									affairs, and because we establish a one-to-one relationship
									with each of our clients, our advice will be tailored for your
									business. Our flexibility and adaptability will ensure we can
									help you get the best result.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="section" id="services">
					<div className="container text-center">
						<div className="row rowmobile d-none">
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="col-sm-3 detimg">
									<img src={serv1} alt="service 1" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Business Advisory Services</h4>
									<p>
										WSC Group’s Business Advisory Services extends well beyond
										traditional accounting activities..{' '}
										<Link to="/our-services/business-advisory-services">
											more
										</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="col-sm-3 detimg">
									<img src={serv2} alt="service 2" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Property Investment</h4>
									<p>
										At WSC Group our special focus is a property investment,
										including advice on portfolios..{' '}
										<Link to="/our-services/property-investment">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="col-sm-3 detimg">
									<img src={serv3} alt="service 3" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Superannuation</h4>
									<p>
										With the importance of superannuation to provide adequate
										capital for retirement, WSC..{' '}
										<Link to="/our-services/superannuation">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="col-sm-3 detimg">
									<img src={serv4} alt="service 4" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Audit and Assurance</h4>
									<p>
										An audit or review is a legal requirement for some
										organisations We act as independent..{' '}
										<Link to="/our-services/audit">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="col-sm-3 detimg">
									<img src={serv5} alt="service 5" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Financial Planning</h4>
									<p>
										Financial planning is much more than choosing products and
										funds Quality in financial..{' '}
										<Link to="/our-services/financial-planning">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="col-sm-3 detimg">
									<img src={serv6} alt="service 6" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Loans</h4>
									<p>
										WSC Group - Finance can provide you with finance for most
										situations, including: home loans..{' '}
										<Link to="/our-services/loans">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="col-sm-3 detimg">
									<img src={serv7} alt="service 7" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Cloud Accounting</h4>
									<p>
										Cloud accounting allows for real-time reporting of your
										business performance without the burden of having to install
										and maintain software
									</p>
								</div>
							</div>
						</div>
						<div className="row row1">
							<div className="col-md-4 col-sm-4 col-xs-6 d-inherit">
								<div className="col-sm-3 detimg">
									<img src={serv1} alt="service 1" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Business Advisory Services</h4>
									<p>
										WSC's Business Advisory Services extends well beyond
										traditional accounting activities..{' '}
										<Link to="/our-services/business-advisory-services">
											more
										</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6 d-inherit">
								<div className="col-sm-3 detimg">
									<img src={serv2} alt="service 2" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Property Investment</h4>
									<p>
										In WSC Group our special focus is a property investment,
										including advice on portfolios..{' '}
										<Link to="/our-services/property-investment">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6 d-inherit">
								<div className="col-sm-3 detimg">
									<img src={serv3} alt="service 3" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Superannuation</h4>
									<p>
										With the importance of superannuation to provide adequate
										capital for retirement, WSC..{' '}
										<Link to="/our-services/superannuation">more</Link>
									</p>
								</div>
							</div>
						</div>
						<div className="row row2">
							<div className="col-md-4 col-sm-4 col-xs-6 d-inherit">
								<div className="col-sm-3 detimg">
									<img src={serv4} alt="service 4" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Audit and Assurance</h4>
									<p>
										An audit or review is a legal requirement for some
										organisations We act as independent..{' '}
										<Link to="/our-services/audit">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6 d-inherit">
								<div className="col-sm-3 detimg">
									<img src={serv5} alt="service 5" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Financial Planning</h4>
									<p>
										Financial planning is much more than choosing products and
										funds Quality in financial..{' '}
										<Link to="/our-services/financial-planning">more</Link>
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6 d-inherit">
								<div className="col-sm-3 detimg">
									<img src={serv6} alt="service 6" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Loans</h4>
									<p>
										WSC Group - Finance can provide you with finance for most
										situations, including: home loans..{' '}
										<Link to="/our-services/loans">more</Link>
									</p>
								</div>
							</div>
						</div>
						<div className="row row2">
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="hidden" />
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6 d-inherit">
								<div className="col-sm-3 detimg">
									<img src={serv7} alt="service 7" />
								</div>
								<div className="col-sm-9 detdet text-left">
									<h4>Cloud Accounting</h4>
									<p>
										Cloud accounting allows for real-time reporting of your
										business performance without the burden of having to install
										and maintain software
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-6">
								<div className="hidden" />
							</div>
						</div>
					</div>
				</div>

				<div className="section" id="whoweare">
					<div className="container">
						<div className="row">
							<div className="col-md-4 col-sm-4 col-xs-4 detleft">
								<img src={aboutLogo} alt="about logo" />
								<h3>ABOUT US</h3>
								<h1>Who Are We?</h1>
							</div>
							<div className="col-md-5 col-sm-5 col-xs-5 text-left detcent">
								<p>
									<strong>WSC Group </strong>provides holistic financial
									services and advice to clients across Australia.
								</p>
								<p>
									Our business is people focused because we believe our approach
									to clients’ financial needs should be as unique as they are.
									Through understanding how our clients conduct their business
									together with their financial goals, we can offer
									straightforward advice and guidance.
								</p>
								<p>
									At WSC Group, the skills, knowledge and expertise of our team
									have been developed through practical and continuous
									professional development.
								</p>
								<p>
									With offices in Sydney South (Head Office), Sydney CBD,
									Brisbane, Gold Coast, Melbourne and Newcastle together with
									serviced office locations at Perth, Canberra and Adelaide and
									video conferencing facilities, contact WSC Group now to
									arrange your complimentary initial meeting to get on the path
									to achieving your business and financial goals.
								</p>
								<div className="btn">
									<Link to="/about-us/who-are-we">
										{' '}
										<i className="fa fa-long-arrow-right" aria-hidden="true" />
										Learn More
									</Link>
								</div>
							</div>
						</div>
						<div className="about-bg-container" />
					</div>
				</div>

				<div className="section" id="directors">
					<div className="container text-center">
						<h6 className="staffhead">STAFF</h6>
						<h1 className="ttle">OUR DIRECTORS</h1>
						<div className="row">
							<div className="col-md-4 col-sm-6 col-xs-6 directors">
								<div className="imgdet">
									<img src={director1} alt="director 1" />
									<div className="detsdirect">
										<p>
											David Shaw is the CEO of WSC Group. Under David’s
											directorship, WSC Group is focused on delivering
											remarkable value to clients across Australia...{' '}
										</p>
										<a href="/about-us/david-shaw-ceo">MORE</a>
									</div>
								</div>
								<h5>CEO & Founder</h5>
								<p>David Shaw</p>
							</div>
							<div className="col-md-4 col-sm-6 col-xs-6 directors">
								<div className="imgdet">
									<img src={director2} alt="director 2" />
									<div className="detsdirect">
										<p>
											Catherine Simons is a Director and the Chief Operating
											Officer of WSC Group. Catherine works with her clients to
											assist them build more profitable businesses ...{' '}
										</p>
										<a href="/about-us/catherine-simons">MORE</a>
									</div>
								</div>
								<h5>National Accounting Director (COO)</h5>
								<p>Catherine Simons</p>
							</div>
							<div className="col-md-4 col-sm-6 col-xs-6 directors">
								<div className="imgdet">
									<img src={director3} alt="director 3" />
									<div className="detsdirect">
										<p>
											Having been in public practice since 2004, Lindsay has
											developed a broad knowledge...{' '}
										</p>
									</div>
								</div>
								<h5>National Superannuation Director</h5>
								<p>Lindsay Davis</p>
							</div>
						</div>
						<div className="row justify-content-md-center">
							<div className="col-md-4 col-md-offset-2 col-sm-6 col-xs-6 directors">
								<div className="imgdet">
									<img src={director4} alt="director 4" />
									<div className="detsdirect">
										<p>
											Sarah Cox is the National Administration Director who's
											role is to deliver professional and efficient management
											of all the offices nationally...{' '}
										</p>
										<a href="/about-us/sarah-cox">MORE</a>
									</div>
								</div>
								<h5>National Administration Director</h5>
								<p>Sarah Cox</p>
							</div>
							<div className="col-md-4 col-sm-6 col-xs-6 col-xs-offset-3 directors">
								<div className="imgdet">
									<img src={director5} alt="director 5" />
									<div className="detsdirect">
										<p>
											Todd Hearne oversees our Newcastle operations. Having 18
											years’ experience as a Chartered Accountant and a
											Registered Tax Agent, Todd’s focus is on providing a high
											level of service to WSC Group clients...
										</p>
										<a href="/about-us/todd-hearne">MORE</a>
									</div>
								</div>
								<h5>Director</h5>
								<p>Todd Hearne</p>
							</div>
						</div>
					</div>
				</div>

				<div className="section" id="testimonial">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-xs-6 whatclientsay">
								<h3>WHAT OUR CLIENT'S SAY</h3>
								<div className="slick-testmnial">
									<div>
										<ClientSwiper slidesperview={1}>
											<div className="testitem">
												<div className="imgdet">
													<div className="row">
														{/*<div className="col-sm-4 imgdet2 hidden">
															<img
																src={testimonialIcon}
																alt="testimonial icon 1"
															/>
														</div>*/}
														<div className="col-sm-8 namedet">
															<h4>Steve & Kerry Bishop</h4>
															<p className="sub">Happy Client</p>
															<ul className="stars">
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
															</ul>
														</div>
													</div>
												</div>
												<h4>
													" David Shaw has been our accountant since late 2005
													after a recommendation from a friend that knew we were
													looking around for a property savvy accountant.
													Initially Kerry & I travelled from the Hunter Valley a
													couple of times a year to meet with David to discuss
													our tax return planning and our annual PAYG tax
													variation, but now he travels to Newcastle around tax
													time for his client base here, which is great!.. "
												</h4>
											</div>
											<div className="testitem">
												<div className="imgdet">
													<div className="row">
														{/*<div className="col-sm-4 imgdet2 hidden">
															<img
																src={testimonialIcon}
																alt="testimonial icon 2"
															/>
														</div>*/}
														<div className="col-sm-8 namedet">
															<h4>Michael and Anthea Newton</h4>
															<p className="sub">Happy Client</p>
															<ul className="stars">
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
															</ul>
														</div>
													</div>
												</div>
												<h4>
													" We have been utilising the services of the David
													Shaw and Sarah Cox since 2009. Throughout our
													relationship, David’s knowledge and passion of all
													things property-related has helped us feel confident
													about our property portfolio. David and Sarah are
													professional, yet personable, and always explain
													issues to us in ways that help us to understand our
													tax and property affairs.. "
												</h4>
											</div>
											<div className="testitem">
												<div className="imgdet">
													<div className="row">
														{/*<div className="col-sm-4 imgdet2 hidden">
															<img
																src={testimonialIcon}
																alt="testimonial icon 3"
															/>
														</div>*/}
														<div className="col-sm-8 namedet">
															<h4>Michael Brahim</h4>
															<p className="sub">Happy Client</p>
															<ul className="stars">
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
															</ul>
														</div>
													</div>
												</div>
												<h4>
													" I have had the pleasure utilizing Catherine Simons’
													services about six years ago when I changed
													accountants and have not looked back since. From my
													personal tax returns to my company’s returns and
													advice in general, Catherine has always been there to
													assist and give guidance on best practices.. "
												</h4>
											</div>
											<div className="testitem">
												<div className="imgdet">
													<div className="row">
														{/*<div className="col-sm-4 imgdet2 hidden">
															<img
																src={testimonialIcon}
																alt="testimonial icon 4"
															/>
														</div>*/}
														<div className="col-sm-8 namedet">
															<h4>Judi Burke</h4>
															<p className="sub">Happy Client</p>
															<ul className="stars">
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
																<li>
																	<i
																		className="fa fa-star"
																		aria-hidden="true"
																	/>
																</li>
															</ul>
														</div>
													</div>
												</div>
												<h4>
													" We changed our accountants 6 years ago and I have
													dealt with Lindsay Davis from WSC Group Menai ever
													since then. From the outset Lindsay has proved to be a
													real asset to our business. He is personable, very
													knowledgeable and when he explains things to me he
													does so in layman’s terms so I completely
													understand.."
												</h4>
											</div>
										</ClientSwiper>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-sm-6 col-xs-6 subscribe">
								<h3>SUBSCRIBE</h3>
								<p>
									To keep up to date with the latest in{' '}
									<strong>WSC Group</strong> news and events, please subscribe
									to our enewsletter by entering your details in the fields
									provided.
								</p>
								<div id="mc_embed_signup">
									<form
										className="validate"
										id="mc-embedded-subscribe-form"
										action="//wscgroup.us12.list-manage.com/subscribe/post?u=c6ff676dbd6024a250b229efe&amp;id=9484e38a6c"
										method="post"
										name="mc-embedded-subscribe-form"
										noValidate=""
										target="_blank"
									>
										<div id="mc_embed_signup_scroll">
											<div className="indicates-required">
												<span className="asterisk">*</span> indicates required
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="mc-field-group">
														<label htmlFor="mce-FNAME">First Name </label>
														<input
															id="mce-FNAME"
															name="FNAME"
															type="text"
															placeholder="First Name.."
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="mc-field-group">
														<label htmlFor="mce-LNAME">Last Name </label>
														<input
															id="mce-LNAME"
															name="LNAME"
															type="text"
															placeholder="Last Name.."
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="mc-field-group">
														<label htmlFor="mce-MMERGE3">Company: </label>
														<input
															id="mce-MMERGE3"
															name="MMERGE3"
															type="text"
															placeholder="Company.."
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="mc-field-group">
														<label htmlFor="mce-EMAIL">
															Email Address <span className="asterisk">*</span>
														</label>
														<input
															className="required email"
															id="mce-EMAIL"
															name="EMAIL"
															type="email"
															placeholder="Email Address.."
														/>
													</div>
												</div>
											</div>
											<div className="clear" id="mce-responses">
												 
											</div>

											<div style={{ position: `absolute`, left: -5000 }}>
												<input
													tabIndex="-1"
													name="b_c6ff676dbd6024a250b229efe_9484e38a6c"
													type="text"
												/>
											</div>
											<div className="clear">
												<input
													className="button"
													id="mc-embedded-subscribe"
													name="subscribe"
													type="submit"
													value="Subscribe"
												/>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="videoblogs section" id="blogsec">
					<div className="container text-center">
						<h1 className="title-header">VLOG</h1>
						<h5 className="sub">Featured Videos</h5>
						<div className="container video-item">
							<div className="row">
								{this.props.data.allSanityVideos.edges.map(vid => (
									<div
										className="col-md-6 col-sm-6 col-xs-12 text-left"
										key={vid.node.id}
									>
										<div className="videoblog-items">
											<div className="lead-sec">
												<div className="lead-image">
													<a href="/">
														<img
															className="img-responsive"
															src={vid.node.mainImage.asset.fluid.src}
															alt="video img"
														/>
														<em />
													</a>
												</div>
											</div>
											<div className="blog-det">
												<h3 className="blog-title">
													<Link to={vid.node.slug.current}>
														{vid.node.title}
													</Link>
												</h3>
												<div className="row">
													<div className="col-md-8 col-sm-8 col-xs-8">
														<div className="published">
															<i
																className="fa fa-calendar mr-1"
																aria-hidden="true"
																style={{ color: `#fff` }}
															/>
															<date>
																{' '}
																{moment(vid.node.publishedAt).format(
																	'dddd, MMMM Do, YYYY'
																)}
															</date>
														</div>
													</div>
													<div className="col-md-4 col-sm-4 col-xs-4">
														<div className="duration text-right">
															<p style={{ color: `#fff` }}>
																{' '}
																<i
																	className="fa fa-clock-o mr-1"
																	aria-hidden="true"
																/>
																{moment(vid.node.publishedAt).format('h:mm')}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="section" id="more-blog">
							<div className="more-img">
								<a
									className="more-blog-btn hvr-ripple-out"
									href="/latest-videos"
								>
									VIEW ALL VIDEOS
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="section" id="blogsec">
					<div className="container text-center">
						<h1 className="title-header">BLOG</h1>
						<h5 className="sub">Latest News</h5>
						<div className="container news-item">
							<div className="row">
								{this.props.data.allSanityNews.edges.map(indexnews => (
									<div className="col-md-6 col-sm-6 col-xs-12 text-left">
										<div className="newss-items">
											<div className="lead-sec">
												<div className="lead-image">
													<img
														src={
															indexnews &&
															indexnews.node &&
															indexnews.node.mainImage &&
															indexnews.node.mainImage.asset &&
															indexnews.node.mainImage.asset.fluid &&
															indexnews.node.mainImage.asset.fluid.src
														}
														alt="News Banner"
													/>
												</div>
											</div>
											<div className="blog-det">
												<h3 className="blog-title">
													<div className="published">
														<i className="fa fa-clock-o" aria-hidden="true" />
														<b>{indexnews.node.publishedAt}</b>
													</div>
													<Link
														to={
															indexnews &&
															indexnews.node &&
															indexnews.node.slug &&
															indexnews.node.slug.current
														}
													>
														{indexnews.node.title}
													</Link>
												</h3>
												<div className="short-desc">
													<p>{indexnews.node.excerpt}</p>
												</div>
											</div>
											<div className="linksbtn">
												<div className="row">
													<div className="col-md-7 col-sm-7 col-xs-8 text-left blogdetpostedby">
														<ul className="pstedimg">
															<li className="imgdt">
																<img src={postImg} alt="posted img" />
															</li>
															<li className="postdtxt">
																<p>
																	<i>Posted By:</i>{' '}
																	<b>{indexnews.node.author.name}</b>
																</p>
															</li>
														</ul>
													</div>
													<div className="col-md-5 col-sm-5 col-xs-4 text-right blogdetlinks">
														<div className="rightbtns">
															<ul>
																<li>
																	<Link to="">
																		<i
																			className="fa fa-share-alt"
																			aria-hidden="true"
																		/>
																	</Link>
																</li>
																<li>
																	{
																		<Link
																			to={
																				indexnews &&
																				indexnews.node &&
																				indexnews.node.slug &&
																				indexnews.node.slug.current
																			}
																		>
																			<i
																				className="fa fa-external-link"
																				aria-hidden="true"
																			/>
																		</Link>
																	}
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="section" id="more-blog">
							<div className="more-img">
								<a className="more-blog-btn hvr-ripple-out" href="/latest-news">
									VIEW ALL NEWS
								</a>
							</div>
						</div>
					</div>
				</div>
				<div id="contact_sechome">
					<div className="container text-center">
						<div className="row">
							<div className="col-md-8 det">
								<h2>
									We help you achieve greater success, wealth and financial
									security while you maintain your desired work-life balance
								</h2>
							</div>
							<div className="col-md-4 detbtn">
								<a href="/page/contact-us.html">
									Contact Us
									<i className="fa fa-arrow-circle-right" aria-hidden="true">
										{' '}
									</i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default IndexPage

export const indexPageQuery = graphql`
	query indexPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
		allSanityNews(limit: 2, sort: { fields: publishedAt, order: DESC }) {
			edges {
				node {
					id
					title
					slug {
						current
					}
					mainImage {
						asset {
							fluid {
								src
							}
						}
					}
					excerpt
					publishedAt(formatString: "dddd, MMMM DD, YYYY")
					author {
						name
					}
				}
			}
		}

		allSanityVideos(sort: { fields: publishedAt, order: DESC }, limit: 2) {
			edges {
				node {
					id
					title
					slug {
						current
					}
					publishedAt
					mainImage {
						asset {
							fluid {
								src
							}
						}
					}
				}
			}
		}
	}
`
