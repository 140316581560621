import React from 'react'
import Swiper from 'react-id-swiper'
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm'
import 'react-id-swiper/src/styles/css/swiper.css'

class ClientSwiper extends React.Component {
	render() {
		const { children, slidesperview } = this.props
		const params = {
			modules: [Pagination, Navigation],
			slidesPerView: slidesperview,
			containerClass: 'swiper-container',
			loop: true,
			autoplay: {
				delay: 2000,
				disableOnInteraction: false,
			},
			pagination: {
				el: '.swiper-pagination2',
				type: 'bullets',
				clickable: true,
			},
			// breakpoints: {
			//   768: {
			//     slidesPerView: 3,
			//     spaceBetween: 30
			//   },
			//   640: {
			//     slidesPerView: 2,
			//     spaceBetween: 20
			//   },
			//   320: {
			//     slidesPerView: 1,
			//     spaceBetween: 10
			//   }
			// }
		}
		return <Swiper {...params}>{children}</Swiper>
	}
}

export default ClientSwiper
